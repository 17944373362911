
import { Component, Vue } from 'vue-property-decorator';
import { AuthService } from '@/services';
import OnboardLayout from '@/components/OnboardLayout.vue';
import { mdiEye, mdiEyeOff } from '@mdi/js';

@Component({
  components: { OnboardLayout }
})
export default class ResetPassword extends Vue {
  form = {
    newPassword: ''
  };
  isValidLink = false;
  visible = false;

  code = this.$route.params.code;

  beforeMount() {
    this.checkCode();
  }

  get appendIcon(): string | null {
    return this.visible ? mdiEye : mdiEyeOff;
  }
  get type(): string {
    return this.visible ? 'text' : 'password';
  }

  onToggleVisibility(): void {
    this.visible = !this.visible;
  }

  /**
   * Check user code
   */
  async checkCode(): Promise<void> {
    try {
      await AuthService.checkCode({ code: this.code });
    } catch (error) {
      this.$notifyError(this.$getError(error));
      return;
    }

    this.isValidLink = true;
  }

  /**
   *  Send a new password
   */
  async onSend() {
    const { newPassword } = this.form;

    let getError = false;

    try {
      await AuthService.resetPassword(this.code, newPassword);
    } catch (e) {
      this.$notifyError(this.$getError(e));
      getError = true;
      // this.formObserver.setErrors({ newPassword: e.response.data.message });
      // return;
    }

    if (!getError) {
      this.$router.replace({
        path: '/login'
      });
    }
  }
}
